@font-face {
  font-family: "Rubik-Regular";
  src: local("Rubik-Regular"), url(../../assets/fonts/Rubik-Regular.ttf);
}

.contact-container{
  margin: 1em 0;
  background-color: var(--background-color);
  border-width: var(--borderWidth);
  border-style: solid;
  border-radius: 1em;
  padding: 0.5em;
  border-color: var(--text-color);
}

/* .contact-heading{
  font-size: 2em;
  font-weight: bold;
  color: var(--text-color);
  padding: 0.5em 0;
  text-align: center;
  padding: 0.5em
} */

.contact-form-title{
  padding: 0.5em 0.5em 0;
  font-size: 2em;
  font-weight: bold;
  text-align: left;
  color: var(--text-color);
}

.contact-form{
  font-size: 1em;
  padding: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-form-section{
  margin: 0.5em 0;
  width: 99%;
  font-size: 1em;
  font-weight: bold;
  border: 1px;
  border-style: solid;
  border-color: var(--text-color);
}

.contact-form-message{
  margin: 0.5em auto;
  width: 99%;
  height: 12em;
  font-size: 1em;
  font-weight: bold;
  border: 1px;
  border-style: solid;
  border-color: var(--text-color);
}


.contact-form-buttons-container{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content:center
}

.contact-form-button{
  margin: 0.5em;
  width: 8em;
  padding: 0.75em;
  background-color: var(--text-color);
  border: none;
  border-radius: 1em;
  transition-duration: 0.2s;
  cursor: pointer;
}

.contact-form-button-item{
  padding: 0 1em;
  font-family: Rubik-Regular, serif;
  font-size: 1em;
  font-weight: bold;
  text-decoration: underline 0.2em var(--text-color);
  color: var(--background-color);
  text-underline-offset: 0.2em;
  transition: text-decoration-color 0.2s, text-underline-offset 0.2s
}

.contact-form-button:hover .contact-form-button-item{
  text-decoration-color: var(--background-color);
  text-underline-offset: 0.4em;
}
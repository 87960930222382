@font-face {
  font-family: "Rubik-Regular";
  src: local("Rubik-Regular"), url(../../assets/fonts/Rubik-Regular.ttf);
}


.turd {
  display: block
}
.about-container{
  margin: 1em;
}

.about-intro-container{
  display: block;
/*   grid-template-columns: 20% 80%; */
  margin: 0 0 1em 0;
  background-color: var(--background-color);
  border-width: var(--borderWidth);
  border-style: solid;
  border-radius: 1em;
  border-color: var(--text-color);
}

.about-intro-profilePic{
  display: none;
  margin: 1em;
  width: 80%;
  border-radius: 1em;
}

.about-title{
  padding: 0 0.5em;
  font-family: Rubik-Regular, serif;
  font-size: 2em;
  font-weight: bold;
  color: var(--text-color);
  text-align: left;

}

.about-text{
  padding: 0 1em;
  text-align: left;
  font-family: Rubik-Regular, serif;
  font-size: 1em;
  color: var(--text-color)
}

.about-text > a{
  color: var(--text-color)
}

.about-history-container{
  background-color: var(--background-color);
  border-width: var(--borderWidth);
  border-style: solid;
  border-radius: 1em;
  border-color: var(--text-color);
}

.about-tech-skills{
  margin: 1em 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.about-tech-logo{
  max-width: 3em;
  padding: 0 0.5em;
  transition-duration: 0.2s;
}

.about-tech-logo:hover{
  scale: 1.2
}

@media (max-width: 768px) {
  .about-tech-logo{
    max-width: 2em;
  }

  .about-text{
    font-size: 1em;
  }

  .about-intro-container{
    display: block;

  }
  .about-intro-profilePic{
    display: none;
  }
}
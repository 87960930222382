ÿ@font-face {
  font-family: "Rubik-Regular";
  src: local("Rubik-Regular"), url(../../assets/fonts/Rubik-Regular.ttf);
}

.navbar-container {
  width: 100%;
  max-width: 1280px;
  padding: 1em 0 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.navbar-title-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.navbar-title-profilePic {
  margin: 0 1em 1em 1em;
  width: 6em;
  border-radius: 100px
}

.navbar-title-name {
  text-align: left;
  font-family: Rubik-Regular, serif;
  font-size: 2em;
  font-weight: bold;
  color: var(--text-color);
  text-decoration: none;
  min-width: 8em
}

.navbar-title-text{
  display: flex;
  flex-direction: column;
  justify-content: start;
  flex-grow: 4;
}

.navbar-title-link > img {
  max-width: 1em
}

.navbar-title-links{
  margin: 1em 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-items: center;
  align-items: center;

}
/* .navbar-title-button {
  margin: 1em;
  padding: 1em;
  background-color: var(--text-color);
  border: none;
  border-radius: 1em;
  transition-duration: 0.2s;
  cursor: pointer;
} */

/* #################################################### */

.navbar-links-desktop {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  list-style-type: none;
  justify-content: end;
  align-items: center;
  margin: 0;
  padding: 0;
  z-index: 1;
  height: 3em
}

.navbar-links-desktop>ul{
  margin: 0 0.5em;
  overflow: hidden;
}

.navbar-link-button {
  margin: 0.5em;
  padding: 0.75em;
  width: 8em;
  background-color: var(--text-color);
  border: none;
  border-radius: 1em;
  transition-duration: 0.2s;
  cursor: pointer;
}

.navbar-link-item {
  font-family: Rubik-Regular, serif;
  font-size: 1em;
  font-weight: bold;
  color: var(--background-color);
  text-decoration: underline 0.2em var(--text-color);
  text-underline-offset: 0.2em;
  transition: text-decoration-color 0.2s, text-underline-offset 0.2s
}

.navbar-link-button:hover .navbar-link-item {
  text-decoration-color: var(--background-color);
  text-underline-offset: 0.4em;
}

.navbar-darkmode-toggle {
  width: 3em;
  height: 3em
}

.navbar-darkmode-toggle>img {
  width: 3em;

}

/* #################################################### */

.navbar-hamburger-button {
  width: 2em;
  height: 3em;
  z-index: 1;
  cursor: pointer
}

.navbar-links-mobile {
  display: none;
  flex-direction: row;
  flex-grow: 1;
  justify-content: end;
  margin: 0;
  padding: 0;
  z-index: 1;
}

.navbar-mobile-drawer-links {
  flex-direction: column;
  justify-items: center;
  color: var(--text-color);
  list-style-type: none;
  padding: 0 2em;
}

/* #################################################### */

@media (max-width: 768px) {

  .navbar-title-profilePic{
    width: 4em
  }

  .navbar-links-desktop {
    display: none;
  }

  .navbar-title-name{
    font-size: 1.5em;
  }

  .navbar-links-mobile {
    display: flex;
  }

  .navbar-darkmode-toggle>img{
    width: 2em;
    cursor: pointer
  }

  .navbar-hamburger-button>img{
    width: 2em
  }

  .navbar-hamburger-button-image-open {
    width: 2em;
  }
  
  .navbar-hamburger-button-image-close {
    width: 2em;
  }
  
  .navbar-hamburger-button-image-open-darkmode {
    width: 2em;
  }
  
  .navbar-hamburger-button-image-close-darkmode {
    width: 2em;
  }



}

@media (max-width: 425px) {
  .navbar-title-name{
    font-size: 1.5em;
  }
}
@font-face {
  font-family: "Rubik-Regular";
  src: local("Rubik-Regular"), url(./assets/fonts/Rubik-Regular.ttf);
}

.App {
  margin: auto;
  width:100%;
  max-width:968px;
  text-align: center;
}

.section-title{
  font-family: Rubik-Regular, serif;
  font-size: 1.5em;
  font-weight: bold;
  color: var(--text-color);
  text-align: left;
  padding: 0 0.5em
}

.text-section{
  margin: 2em 0;
  padding: 1em;
  background-color: var(--background-color);
  border-radius: 16px;
  box-shadow: 0px 4px 8px;
}

.page-container {
  margin: 1em
}

.box-title {
  font-family: Rubik-Regular, serif;
  padding: 0.5em 0.5em 0;
  font-size: 1.5em;
  font-weight: bold;
  text-align: left;
  color: var(--text-color);
}

.box-subheading{
  font-family: Rubik-Regular, serif;
  padding: 0.5em 1em;
  font-size: 1em;
  font-weight: bold;
  text-align: left;
  color: var(--text-color);
}

.box-text {
  font-family: Rubik-Regular, serif;
  padding: 1em;
  font-size: 1em;
  text-align: left;
  color: var(--text-color);
}






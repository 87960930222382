.home-gallery-nav{
  margin: 1em 0;
  width: 100%;
}

.home-gallery-pic-container {
  width: 100%
}

.home-gallery-pic{
  width: 100%;
  border-color: #101010;
  border-width: 2px;
  border-style: solid;
  transition: 0.2s;
}

.home-gallery-link{
  font-size: 2em;
  font-weight: bold;
  color: var(--text-color);
  text-decoration: underline 0.2em var(--background-color);;
  text-underline-offset: 0.2em;
  transition: text-decoration-color 0.2s, text-underline-offset 0.2s
}

.home-gallery-link:hover{
  text-decoration-color: var(--link-color);;
  text-underline-offset: 0.3em;
}
.projects-container {
  background-color: var(--background-color);
  border-radius: 1em;
  border-width: var(--borderWidth);
  border-style: solid;
  padding: 0.5em;
  border-color: var(--text-color);
}

.projects-title {
  padding: 0 0.5em;
  font-size: 2em;
  font-weight: bold;
  text-align: left;
  color: var(--text-color);
}

.projects-intro {
  padding: 0 1em;
  text-align: left;
  /* font-family: Rubik-Regular, serif; */
  font-size: 1em;
  color: var(--text-color)
}

.projects-card-container-desktop {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  background-color: var(--background-color);
}



/* #################################################################### */


/* @media (max-width: 768px) {
  .projects-container  {
    background-color: red;
  }

  .project-card-description-title {
    font-size: 1em;
  }

  .project-title {
    font-size: 1.5em;
  }
}  */
.gallery-container {
  margin: 1em auto;
  background-color: var(--background-color);
}

.gallery-title {
  font-size: 1.5em;
  font-weight: bold;
  text-align: left;
  color: var(--text-color);
}

.gallery-mainImage {
  margin: 0.5em auto 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.gallery-mainImage-pic {
  width: 100%;
  border: 1px;
  border-style: solid;
  border-color: var(--text-color);;
}

.gallery-thumb-container {
  width: 100%;
  margin: 0.5em 0 0 0;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  align-items: center;
}


.gallery-thumb {
  padding: 0.1em;
  cursor: pointer;
  transition: 0.1s;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  border-style: solid;
  border-width: 1px;
  border-color: var(--text-color);
}

.gallery-thumb>img {
  width: 100%;
}

/* .gallery-thumb:hover{
  scale: 1.2;
  border-color: var(--text-color);
  z-index: 2

} */